import React, { useEffect, useState } from "react";

const SectionTitle = ({ classNames = "", title }) => {
  return (
    <h2
      className={`text-typo-900 font-semibold text-lg md:text-2.5xl text-start mb-3 md:mb-4 ${classNames}`}
    >
      {title}
    </h2>
  );
};

export default SectionTitle;
