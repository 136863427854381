import { useTranslation } from "react-i18next";
import { cls } from "~/old-app/utils/helpers/cls";
import Carousel from "../../Carousel";
import SectionTitle from "../../Typography/SectionTitle";

const Section = ({
  card,
  order_by,
  items,
  isLoading,
  order,
  sale,
  title,
  itemsCount,
  breakpoints,
  onSeeAllClick,
  showNavigation = true,
  titleTag = "h3",
}) => {
  const { t } = useTranslation();

  return (
    <section className="container w-full flex flex-col py-5">
      <header className="w-full flex items-center justify-between">
        <SectionTitle title={title} />

        {!!onSeeAllClick && (
          <div
            onClick={onSeeAllClick}
            className={cls(
              "text-primary text-sm font-semibold cursor-pointer text-primary-500",
              {
                "mb-4": !title,
              }
            )}
          >
            {t("global.seeAll")}
          </div>
        )}
      </header>

      <Carousel
        items={items}
        card={card}
        itemsCount={itemsCount}
        isLoading={isLoading}
        breakpoints={breakpoints}
        showNavigation={showNavigation}
        titleTag={titleTag}
      />
    </section>
  );
};

export default Section;
